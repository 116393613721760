import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

export interface EmojiCardItemType {
  icon: string;
  name: 'fine' | 'doubt' | 'support' | 'event';
  title: string;
  textDataKey: string;
}

interface PropsType {
  type?: 'card' | 'tab';
  item: EmojiCardItemType;
  splitName: string;
  isActive?: boolean;
  onClick: (item: EmojiCardItemType) => void;
}

export const EmojiCard: React.FC<PropsType> = React.memo(
  ({ item, splitName, type = 'card', isActive, onClick }) => {
    const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
    const widgetData = useSelector(
      (state: IAppState) => state.emojiWidget.data,
    );

    const { top100Prefix } = useTop100Context();

    const { icon, title, name } = item;

    return (
      <button
        type="button"
        className={cn(
          s.root,
          s[type],
          isActive && s.active,
          isMobile && s.mobile,
        )}
        onClick={() => onClick(item)}
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          `${splitName}::${widgetData?.sign}::${name}`,
        )}
      >
        <Icon id={icon} className={s.icon} />
        <Typography variant="defaultMedium" className={s.text}>
          {title}
        </Typography>
      </button>
    );
  },
);

EmojiCard.displayName = 'EmojiCard';
