import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';
import { YandexEvent } from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';

import { EmojiCard, EmojiCardItemType } from '../../EmojiCard';
import { EmoForecast, DisplayVariant, Reactions } from '../../EmoForecast';

import s from './styles.css';

const CARDS = [
  {
    name: 'doubt',
    icon: 'emoji-doubt',
    title: 'Пребываю в сомнениях',
    textDataKey: 'in_doubt_text',
  },
  {
    name: 'event',
    icon: 'emoji-event',
    title: 'Жду важное событие',
    textDataKey: 'important_event_text',
  },
];

interface PropsType {
  splitName: string;
  widgetData: EmoWidgetDataType;
}

export const Cards: React.FC<PropsType> = React.memo(
  ({ splitName, widgetData }) => {
    const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

    const [forecastData, setForecastData] = useState<EmojiCardItemType | null>(
      null,
    );
    const [reactions, setReactions] = useState<Record<string, Reactions>>({});

    const cardHandleClick = useCallback((item: EmojiCardItemType) => {
      setForecastData(item);
      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: 'emo_widget_click',
      });
    }, []);

    const onBackHandleClick = useCallback(() => setForecastData(null), []);

    return (
      <div className={cn(s.root, isMobile && s.mobile)}>
        {!forecastData && (
          <div className={s.emoji}>
            <Typography variant="h3" className={s.emojiTitle}>
              Получите совет дня! Выберите своё настроение
            </Typography>
            <div className={s.cards}>
              {CARDS.map((item: EmojiCardItemType) => (
                <EmojiCard
                  key={item.name}
                  item={item}
                  splitName={splitName}
                  onClick={cardHandleClick}
                />
              ))}
            </div>
          </div>
        )}
        {forecastData && (
          <EmoForecast
            displayVariant={DisplayVariant.Card}
            splitName={splitName}
            widgetData={widgetData}
            forecastData={forecastData}
            reactions={reactions}
            onReaction={(reaction: Record<string, Reactions>) =>
              setReactions(reaction)
            }
            onBack={onBackHandleClick}
          />
        )}
      </div>
    );
  },
);

Cards.displayName = 'Cards';
