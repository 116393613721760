import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { EmojiCardItemType } from '../../EmojiCard';
import { EmoForecast, DisplayVariant, Reactions } from '../../EmoForecast';

import s from './styles.css';

const DATA: EmojiCardItemType[] = [
  {
    name: 'fine',
    icon: 'emoji-fine',
    title: 'Как сделать день еще лучше',
    textDataKey: 'everything_fine_text',
  },
  {
    name: 'doubt',
    icon: 'emoji-doubt',
    title: 'Чтобы избежать ошибок',
    textDataKey: 'in_doubt_text',
  },
  {
    name: 'support',
    icon: 'emoji-support',
    title: 'Если чувствуете себя без сил',
    textDataKey: 'relationship_difficulties_text',
  },
  {
    name: 'event',
    icon: 'emoji-event',
    title: 'Что делать, чтобы важное событие прошло отлично',
    textDataKey: 'important_event_text',
  },
];

interface PropsType {
  splitName: string;
  widgetData: EmoWidgetDataType;
}

export const Text: React.FC<PropsType> = React.memo(
  ({ splitName, widgetData }) => {
    const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

    const [reactions, setReactions] = useState<Record<string, Reactions>>({});

    return (
      <div className={cn(s.root, isMobile && s.mobile)}>
        {DATA.map((item: EmojiCardItemType, index) => {
          const isLast = DATA.length - 1 === index;
          const isFirst = index === 0;

          return (
            <EmoForecast
              key={item.name}
              className={cn(isFirst && s.first, isLast && s.last)}
              displayVariant={DisplayVariant.Text}
              withIcon
              withReactions={isLast}
              withHint={isLast}
              splitName={splitName}
              widgetData={widgetData}
              reactions={reactions}
              onReaction={(reaction: Record<string, Reactions>) =>
                setReactions(reaction)
              }
              forecastData={item}
            />
          );
        })}
      </div>
    );
  },
);

Text.displayName = 'Text';
